import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'types/models/enterprise';
import { SiteId } from 'types/models/site';

import ActionTypes from './constants';

export const fetchExternalDevicesRequest = (enterpriseId: EnterpriseId, siteId: SiteId) =>
  action(ActionTypes.FETCH_EXTERNAL_DEVICES_REQUEST, { enterpriseId, siteId });

export const fetchExternalDevicesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.FETCH_EXTERNAL_DEVICES_SUCCESS, { response });

export const fetchExternalDevicesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.FETCH_EXTERNAL_DEVICES_FAILURE,
    { message, error },
    undefined,
    true
  );