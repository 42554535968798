import 'react-app-polyfill/ie11';
import {
  BarController,
  BarElement,
  Chart,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScatterController,
  TimeSeriesScale,
  Tooltip
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { ConnectedRouter } from 'connected-react-router';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { PersistGate } from 'redux-persist/es/integration/react';
import 'chartjs-adapter-moment';

import 'typeface-roboto';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import App from 'routes/App';
import configureStore from 'store/configureStore';
import history from 'utils/history';

import * as serviceWorker from './serviceWorker';

import './index.less';

Chart.register(
  annotationPlugin,
  BarController,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeSeriesScale,
  Tooltip
);

const {
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
} = process.env;

// eslint-disable-next-line no-console
console.log(REACT_APP_VERSION);

TagManager.initialize({
  gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID as string
});
// Fire pageview 'GA4 - Virtual Page View' tag in GTM (prod). GTM then sends the
// data to Google Analytics for page view tracking.
TagManager.dataLayer({
  dataLayer: {
    event: 'pageview'
  }
});

// Last page visited before the current page.
let entryUrlWithoutOrigin =
  document.location.pathname +
  document.location.search +
  document.location.hash;
let entryPageTitle = document.title;

history.listen((location) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      entryUrlWithoutOrigin,
      entryPageTitle
    }
  });
  entryUrlWithoutOrigin = location.pathname + location.search + location.hash;
  entryPageTitle = document.title;
});

const { persistor, store } = configureStore(history);
(async () => {
  // https://github.com/launchdarkly/react-client-sdk/issues/130#issuecomment-1656260383
  // The practical identify call is made in enterpriseScaffold/saga.ts.
  console.info('🔽 Before we receive the user detail, we use the empty context to prevent LaunchDarkly from sending out anonymous identify calls.');
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: {}
  });
  render(
    <Provider store={store}>
      <PersistGate loading={<LoadingSpinner useLogo />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <CompatRouter>
            <LDProvider>
              <App />
            </LDProvider>
          </CompatRouter>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('farmbot')
  );
})();

serviceWorker.unregister();
