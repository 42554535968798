import { message as antdMessage } from 'antd';
import axios, { AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import { REHYDRATE } from 'redux-persist';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import NetworkActionTypes from 'store/modules/network/constants';
import { selectNetworkOnline } from 'store/modules/network/selectors';
import { selectSamplePoints } from 'store/modules/samplePoints/selectors';
import { TriggersState } from 'store/modules/triggers/types';
import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint from 'types/models/samplePoint';
import Trigger from 'types/models/trigger';
import { ConditionType, EventLevel, ValueType } from 'types/trigger.enum';
import makeTriggerSpecialKey from 'utils/make-trigger-special-key';
import {
  deleteRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  enableTriggerBulk,
  enableTriggerBulkSuccess,
  enableTriggerRequest,
  enableTriggerSuccess,
  removePendingIsEnabledChange,
  removeTrigger,
  removeTriggerFailure,
  removeTriggers,
  setPendingIsEnabledChange,
  setPendingIsEnabledChangeBulk,
  setTrigger
} from './actions';
import ActionTypes from './constants';
import {
  selectEnabledTriggersKeyedBySpecialKey,
  selectPendingIsEnabledChanges
} from './selectors';
import { getCalculatedVolumeMappings } from '../volumeMappings/actions';
import { createVolumeMappingQueryFromTrigger } from '../volumeMappings/helper';

export function* queueEnableAlertTrigger(
  action: ReturnType<typeof enableTriggerRequest>
) {
  const {
    payload: { samplePointId, eventLevel, valueType, conditionType, values }
  } = action;

  const specialKey = makeTriggerSpecialKey(
    samplePointId,
    eventLevel,
    valueType,
    conditionType
  );

  yield all([
    put(setPendingIsEnabledChange(specialKey, values)),
    put(enableTriggerSuccess(samplePointId))
  ]);
}

export function* watchEnableAlertTriggerRequest() {
  yield takeEvery(
    ActionTypes.ENABLE_ALERT_TRIGGER_REQUEST,
    queueEnableAlertTrigger
  );
}

export function* queueEnableAlertTriggerBulk(
  action: ReturnType<typeof enableTriggerBulk>
) {
  const {
    payload: { triggerOptions }
  } = action;

  // Iterate over triggerOptions, collecting 'specialKeys', trigger values &
  // samplePointIds in a single pass.
  const [specialKeys, triggerValues, samplePointIds] = triggerOptions.reduce(
    (
      [specialKeysAcc, valuesAcc, samplePointIdsAcc],
      { samplePointId, eventLevel, valueType, conditionType, values }
    ) => [
        [
          ...specialKeysAcc,
          makeTriggerSpecialKey(
            samplePointId,
            eventLevel,
            valueType,
            conditionType
          )
        ],
        [...valuesAcc, values],
        [...samplePointIdsAcc, samplePointId]
      ],
    [[], [], []] as [string[], any[], number[]]
  );

  yield all([
    put(setPendingIsEnabledChangeBulk(specialKeys, triggerValues)),
    put(enableTriggerBulkSuccess(samplePointIds))
  ]);
}

export function* watchEnableAlertTriggerBulkRequest() {
  yield takeEvery(
    ActionTypes.ENABLE_ALERT_TRIGGER_BULK_REQUEST,
    queueEnableAlertTriggerBulk
  );
}

export function* processEnabledTriggersUpdateQueue(): SagaIterator {
  const networkOnline: boolean | undefined = yield select(selectNetworkOnline);
  const samplePoints: Record<string, SamplePoint> =
    yield select(selectSamplePoints);
  const triggers: Record<string, Trigger> = yield select(
    selectEnabledTriggersKeyedBySpecialKey
  );

  const pendingIsEnabledChanges: TriggersState['pendingIsEnabledChanges'] =
    yield select(selectPendingIsEnabledChanges);

  const specialKeys = Object.keys(pendingIsEnabledChanges);

  if (networkOnline) {
    for (const specialKey of specialKeys) {
      const [samplePointId, eventLevel, valueTypeString, conditionType] =
        specialKey.split('.');

      const valueType = +valueTypeString as ValueType;
      const trigger = triggers[specialKey];
      const triggerId = trigger?.id;
      const samplePoint = samplePoints[samplePointId];
      const assetTypeId = samplePoint?.assetTypeId;
      const spIsAdvancedDam = samplePoint?.config?.enabledVolumeMapping;
      const hasMultipleUnitTriggers =
        spIsAdvancedDam ||
        [
          AssetTypeCode.FUEL_TANK,
          AssetTypeCode.LIQUID_FERTILISER_TANK
        ].includes(assetTypeId);

      const pendingChange = cloneDeep(pendingIsEnabledChanges[specialKey]);
      try {
        if (!triggerId && isNil(pendingChange.value)) {
          yield put(removePendingIsEnabledChange(specialKey));
          // eslint-disable-next-line no-continue
          continue;
        }
        const volumeMappingsQuery = isNil(pendingChange.value)
          ? {}
          : createVolumeMappingQueryFromTrigger(
            pendingChange.value,
            pendingChange.valueType
          );

        if (triggerId && pendingChange.value === null) {
          // # Delete trigger
          yield call(deleteRequest, `trigger/${triggerId}`);
          yield put(removeTrigger({ triggerId, specialKey }));
        } else if (triggerId && trigger.valueType === valueType) {
          // # Update trigger
          const response = yield call(patchRequest, `trigger/${triggerId}`, {
            value: pendingChange.value,
            duration: pendingChange.duration,
            useForecast: pendingChange.useForecast,
            valueType: pendingChange.valueType
          });
          const { data } = response;
          const updatedTrigger = {
            ...data,
            value: data.value
          };
          yield put(setTrigger({ trigger: updatedTrigger, specialKey }));
        } else {
          // # Add trigger

          // The following is a temporary solution.

          // For sample points whose triggers support multi units, we have to
          // delete different value types of the trigger to make sure there's
          // only one trigger for each trigger type. For example, when we edit
          // volume-based dam critical high trigger, we clear all the possible
          // existing dam critical high triggers including RL-based and level-
          // based.

          // Plus, since the trigger now has different value types, the special
          // key is not unique any more.

          if (hasMultipleUnitTriggers) {
            const specialKeysToTest = [
              ValueType.REDUCED_LEVEL,
              ValueType.VOLUME,
              ValueType.VALUE
            ].map((valType) =>
              makeTriggerSpecialKey(
                samplePoint.id,
                eventLevel as unknown as EventLevel,
                valType,
                conditionType as unknown as ConditionType
              )
            );
            const specialKeysToDelete = intersection(
              Object.keys(triggers),
              specialKeysToTest
            );
            if (specialKeysToDelete.length > 0) {
              for (let i = 0; i < specialKeysToDelete.length; i += 1) {
                const key = specialKeysToDelete[i];
                yield call(deleteRequest, `trigger/${triggers[key].id}`);
                yield put(
                  removeTrigger({
                    triggerId: triggers[key].id,
                    specialKey: key
                  })
                );
              }
            }
          }
          // At the moment all the triggers have the same values for
          // thresholdSensitivity, window and timeBeforeReTrigger.
          // timeBeforeReTrigger is different for battery alerts, but we don't
          // create it from FE.
          const response = yield call(postRequest, 'trigger', {
            eventLevel: Number(eventLevel),
            isEnabled: true,
            timeBeforeReTrigger: 604800,
            samplePointId: Number(samplePointId),
            valueType,
            thresholdSensitivity: 5,
            window: 3600,
            duration: pendingChange.duration,
            conditionType: Number(conditionType),
            value: pendingChange.value,
            useForecast: pendingChange.useForecast,
            message: pendingChange.message,
            triggerType: pendingChange.triggerType
          });
          const { data } = response;
          const newTrigger = {
            ...data,
            value: data.value
          };
          yield put(setTrigger({ trigger: newTrigger, specialKey }));
        }
        if (spIsAdvancedDam && volumeMappingsQuery) {
          yield put(
            getCalculatedVolumeMappings(samplePoint.id, volumeMappingsQuery)
          );
        }
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        /**
         * Suppress error handling in the event of a Network Error.
         */
        if (error.message !== 'Network Error') {
          antdMessage.error(
            'Failed to update alert trigger. Please try again'
          );
          yield put(removePendingIsEnabledChange(specialKey));
        }
      }
    }
  }
}

export function* watchSetPendingIsEnableChange() {
  yield takeLatest(
    [
      REHYDRATE,
      NetworkActionTypes.SET_NETWORK_ONLINE,
      ActionTypes.SET_PENDING_IS_ENABLE_CHANGE,
      ActionTypes.SET_PENDING_IS_ENABLE_CHANGE_BULK
    ],
    processEnabledTriggersUpdateQueue
  );
}

export function* requestRemoveTriggers(
  action: ReturnType<typeof removeTriggers>
) {
  const {
    payload: { triggerIds }
  } = action;
  try {
    if (triggerIds.length) {
      yield all(triggerIds.map((id) => call(deleteRequest, `trigger/${id}`)));
    }
  } catch (error) {
    antdMessage.error('Failed to remove alert triggers');
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(removeTriggerFailure(message, error as AxiosError));
  }
}

export function* watchRemoveTriggers() {
  yield takeLatest(ActionTypes.REMOVE_TRIGGERS, requestRemoveTriggers);
}

export default function* triggersSaga() {
  yield all([
    fork(watchEnableAlertTriggerRequest),
    fork(watchEnableAlertTriggerBulkRequest),
    fork(watchSetPendingIsEnableChange),
    fork(watchRemoveTriggers)
  ]);
}
