import { call } from 'redux-saga/effects';

import UserPreference, { EntityId, EntityKind } from 'types/models/user-preference';
import { getRequest, putRequest } from 'utils/redux-saga-requests';

// ================================================
// TYPES
// ================================================
export type FetchUserPreferencesParams = {
  entityKind?: EntityKind;
  /** If specified, must specify an entityKind too */
  entityId?: EntityId;
};

export interface UpdateUserPreferenceDTO extends Omit<UserPreference, 'id' | 'userId'> { }

// ================================================
// REQUESTS
// ================================================
export function* fetchPreferences(
  { entityKind, entityId }: FetchUserPreferencesParams
): Generator<any, UserPreference[], any> {
  const response = yield call(getRequest, 'user-preferences', { params: { entityKind, entityId } });
  return response;
}

export function* editPreference(updatedPreference: UpdateUserPreferenceDTO): Generator<any, UserPreference, any> {
  const response = yield call(putRequest, 'user-preferences', updatedPreference);
  return response;
}