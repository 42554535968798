import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqWith from 'lodash/uniqWith';

import { ApplicationRootState } from 'store/types';
import { LatLong } from 'types/asset';
import { SiteId } from 'types/models/site';
import createDeepEqualSelector from 'utils/create-deep-equal-selector';

export const selectIPCameras = (state: ApplicationRootState) => state.ipCameras;
export const selectIPCamerasAsArray = (state: ApplicationRootState) => Object.values(state.ipCameras);
export const selectIPCamera = (id: number) => (state: ApplicationRootState) => state.ipCameras[id];

export const makeSelectIPCamerasCoordinates = (siteId: SiteId) =>
  createDeepEqualSelector(selectIPCameras, (ipCameras) => {
    const resultWithPossibleDups = Object.values(ipCameras)
      .filter((ipCamera) => ipCamera.siteId === siteId && ipCamera.lat && ipCamera.long)
      .map(({ lat, long }) => ([lat, long] as LatLong));

    const uniqueResults = uniqWith(
      resultWithPossibleDups,
      ([x, y], [x2, y2]) => x === x2 && y === y2
    );

    return orderBy(uniqueResults, '[1]', 'desc');
  });

const selectIPCamerasGroupedByLocation =
  createDeepEqualSelector(
    selectIPCameras,
    (ipCameras) => groupBy(Object.values(ipCameras), ({ lat, long }) => `${lat},${long}`)
  );

export const makeSelectIPCamerasByLocation = (coordinatesString: string) =>
  createDeepEqualSelector(
    selectIPCamerasGroupedByLocation,
    (groupedIPCameras) => groupedIPCameras[coordinatesString] || []
  );