import { ColumnType } from 'antd/lib/table';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import NewTabLink from 'components/atoms/NewTabLink';
import StatusTag from 'components/composites/StatusTag';
import BackOfficeTable, {
  Props as BackOfficeTableProps
} from 'components/tables/BackOfficeTable';
import { DATE_TIME_FORMAT } from 'constants/time';
import {
  selectBackOfficeDeviceCameraSamplePoints,
  selectBackOfficeDevicesAsArray,
  selectBackOfficeDevicesTotal
} from 'store/modules/backOfficeDevices/selectors';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedDevice } from 'store/modules/routerUtils/selectors';
import { red } from 'style/colour-palette';
import { DeviceStatus } from 'types/device.enum';
import { CAMERA_DATA_PLAN_BASE_KB } from 'types/models/camera';
import Device from 'types/models/device';
import Enterprise from 'types/models/enterprise';
import SamplePoint from 'types/models/samplePoint';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';
import { isDeviceArchived } from 'utils/Device/archived/is-archived';

// 15% of the base data in the camera plan.
const CAMERA_DATA_LOW_THRESHOLD = 0.15 * CAMERA_DATA_PLAN_BASE_KB;

const loadAction = 'LOAD_BACK_OFFICE_DEVICES';
const iotURL = `${process.env.REACT_APP_FTMS_BASE_URL}/LRS-Web1/IoT_MonitorSearchPage.action`;

type DeviceTableType = Device & {
  cameraData?: SamplePoint;
  enterprise?: Enterprise;
};

const columns: ColumnType<DeviceTableType>[] = [
  {
    title: 'Name',
    width: 150,
    ellipsis: true,
    fixed: 'left',
    // Show serial number if device doesn't have a name.
    render: ({ name, serialNumber }: Device) => name || serialNumber
  },
  {
    title: 'IoT Site',
    dataIndex: 'serialNumber',
    width: 83,
    ellipsis: true,
    render: (serialNumber) => (
      <NewTabLink href={`${iotURL}?monitorESN=${serialNumber}`} iconSize={22} />
    )
  },
  {
    title: 'Backhaul',
    dataIndex: 'backhaulDesc',
    width: 130,
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Enterprise',
    dataIndex: 'enterprise',
    width: 165,
    ellipsis: true,
    render: (enterprise) => enterprise?.name
  },
  {
    title: 'Site',
    dataIndex: 'site',
    width: 165,
    ellipsis: true,
    render: (site) => site?.name
  },
  {
    title: 'Battery',
    dataIndex: 'batteryLevel',
    width: 95,
    ellipsis: false,
    sorter: true,
    render: (batteryLevel, { id, enterprise }) => {
      if (!batteryLevel) return '';
      // To 2 decimal places without rounding
      const level = batteryLevel?.toString().slice(0, 4);
      if (enterprise) {
        return (
          <NewTabLink
            href={`${constructEnterpriseDomain(enterprise)}/enterprise/account/monitors/${id}`}
            text={`${level} V`}
          />
        );
      }
      return `${level} V`;
    }
  },
  {
    title: 'HW',
    dataIndex: 'hwVersion',
    width: 70,
    ellipsis: true
  },
  {
    title: 'FW',
    dataIndex: 'fwVersion',
    width: 70,
    ellipsis: true
  },
  {
    title: 'Model',
    dataIndex: 'model',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Make',
    dataIndex: 'make',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Camera Attached',
    dataIndex: 'cameraData',
    width: 145,
    ellipsis: true,
    sorter: false,
    render: (cameraData?: SamplePoint) => {
      if (
        !cameraData ||
        cameraData.config?.maxKb === undefined ||
        cameraData.config.kbConsumed === undefined
      )
        return '';

      const {
        config: { maxKb, kbConsumed }
      } = cameraData;

      return (
        <div
          style={
            maxKb - kbConsumed < CAMERA_DATA_LOW_THRESHOLD ? { color: red } : {}
          }
        >
          {`${maxKb - kbConsumed} KB left`}
        </div>
      );
    }
  },
  {
    title: 'Last Data',
    dataIndex: 'lastDataDate',
    width: 110,
    ellipsis: true,
    sorter: true,
    render: (value) => value && moment(value).fromNow()
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 155,
    sorter: true,
    render: (status: DeviceStatus) => <StatusTag status={status} />
  },
  {
    title: 'Last Boot',
    dataIndex: 'lastBootDate',
    width: 110,
    ellipsis: true,
    sorter: true,
    render: (value) => value && moment(value).fromNow()
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    width: 180,
    ellipsis: false,
    sorter: true,
    render: (createdAt: number) => (
      <div>{moment(createdAt).format(DATE_TIME_FORMAT.DEFAULT)}</div>
    )
  }
];

function BackOfficeDevicesTable({
  onPaginationChange
}: Pick<BackOfficeTableProps<Device>, 'onPaginationChange'>): JSX.Element {
  const devices = useSelector(selectBackOfficeDevicesAsArray, shallowEqual);

  const cameraSamplePoints = useSelector(
    selectBackOfficeDeviceCameraSamplePoints,
    shallowEqual
  );

  const cameraData = useMemo(
    () => keyBy(cameraSamplePoints, 'deviceId'),
    [cameraSamplePoints]
  );

  const deviceId = useSelector(
    selectRouterQueryStringSelectedDevice,
    shallowEqual
  );

  const backOfficeDevicesTotal = useSelector(
    selectBackOfficeDevicesTotal,
    shallowEqual
  );

  const rowIdToOnClickAction = useCallback(
    (id: number) =>
      appendValuesToQueryString({
        drawer: undefined,
        selectedDevice: id
      }),
    []
  );

  const data = useMemo(
    () =>
      devices
        .filter((d) => !isDeviceArchived(d))
        .map((device) => ({
          ...device,
          cameraData: cameraData[device.id]
        })),
    [devices, cameraData]
  );

  return (
    <BackOfficeTable<DeviceTableType>
      columns={columns}
      data={data}
      total={backOfficeDevicesTotal}
      loadActionType={loadAction}
      rowIdToOnClickAction={rowIdToOnClickAction}
      onPaginationChange={onPaginationChange}
      emptyText="No devices found"
      selectedRowId={deviceId}
    />
  );
}

export default BackOfficeDevicesTable;
