/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import appScaffoldReducer from './modules/appScaffold/reducer';
import assetTypesReducer from './modules/assetTypes/reducer';
import backOfficeCiboSubscriptionsReducer from './modules/backOfficeCiboSubscriptions/reducer';
import backOfficeDeviceReducer from './modules/backOfficeDevice/reducer';
import backOfficeDeviceMapPointsReducer from './modules/backOfficeDeviceMapPoints/reducer';
import backOfficeDevicesReducer from './modules/backOfficeDevices/reducer';
import backOfficeEnterpriseReducer from './modules/backOfficeEnterprise/reducer';
import backOfficeEnterprisesReducer from './modules/backOfficeEnterprises/reducer';
import backOfficeEnterpriseSitesReducer from './modules/backOfficeEnterpriseSites/reducer';
import backOfficeInvitationsReducer from './modules/backOfficeInvitations/reducer';
import backOfficeUserReducer from './modules/backOfficeUser/reducer';
import backOfficeUsersReducer from './modules/backOfficeUsers/reducer';
import camerasReducer from './modules/cameras/reducer';
import ciboLocationsReducer from './modules/ciboLocations/reducer';
import controlPointsReducer from './modules/controlPoints/reducer';
import countriesReducer from './modules/countries/reducer';
import devicesReducer from './modules/devices/reducer';
import enterpriseReducer from './modules/enterprise/reducer';
import errorReducer from './modules/error/reducer';
import ipCamerasReducer from './modules/integration/ipCameras/reducer';
import invitationReducer from './modules/invitations/reducer';
import layoutReducer from './modules/layout/reducer';
import loadingReducer from './modules/loading/reducer';
import localUserSettingsReducer from './modules/localUserSettings/reducer';
import myPreferencesReducer from './modules/myPreferences/reducer';
import myUserReducer from './modules/myUser/reducer';
import networkReducer from './modules/network/reducer';
import notificationPolicyReducer from './modules/notificationPolicy/reducer';
import recentNotificationsReducer from './modules/recentNotifications/reducer';
import routerUtilsReducer from './modules/routerUtils/reducer';
import samplePointsReducer from './modules/samplePoints/reducer';
import samplePointsStatisticsReducer from './modules/samplePointsStatistics/reducer';
import samplesReducer from './modules/samples/reducer';
import searchReducer from './modules/search/reducer';
import sitesReducer from './modules/sites/reducer';
import statesReducer from './modules/states/reducer';
import timeReducer from './modules/time/reducer';
import triggersReducer from './modules/triggers/reducer';
import usersReducer from './modules/users/reducer';
import volumeMappingsReducer from './modules/volumeMappings/reducer';

export default (history: History, overrides) =>
  combineReducers({
    // key reducers
    router: connectRouter(history),
    appScaffold: appScaffoldReducer,

    // other reducers
    assetTypes: assetTypesReducer,
    backOfficeCiboSubscriptions: backOfficeCiboSubscriptionsReducer,
    backOfficeDevice: backOfficeDeviceReducer,
    backOfficeDeviceMapPoints: backOfficeDeviceMapPointsReducer,
    backOfficeDevices: backOfficeDevicesReducer,
    backOfficeEnterprise: backOfficeEnterpriseReducer,
    backOfficeEnterpriseSites: backOfficeEnterpriseSitesReducer,
    backOfficeEnterprises: backOfficeEnterprisesReducer,
    backOfficeInvitations: backOfficeInvitationsReducer,
    backOfficeUser: backOfficeUserReducer,
    backOfficeUsers: backOfficeUsersReducer,
    cameras: camerasReducer,
    ciboLocations: ciboLocationsReducer,
    controlPoints: controlPointsReducer,
    countries: countriesReducer,
    devices: devicesReducer,
    enterprise: enterpriseReducer,
    error: errorReducer,
    invitations: invitationReducer,
    ipCameras: ipCamerasReducer,
    layout: layoutReducer,
    loading: loadingReducer,
    localUserSettings: localUserSettingsReducer,
    myPreferences: myPreferencesReducer,
    myUser: myUserReducer,
    network: networkReducer,
    notificationPolicy: notificationPolicyReducer,
    recentNotifications: recentNotificationsReducer,
    routerUtils: routerUtilsReducer,
    samplePoints: samplePointsReducer,
    samplePointsStatistics: samplePointsStatisticsReducer,
    samples: samplesReducer,
    search: searchReducer,
    sites: sitesReducer,
    states: statesReducer,
    time: timeReducer,
    triggers: triggersReducer,
    users: usersReducer,
    volumeMappings: volumeMappingsReducer,
    ...overrides
  });
