/**
 * Primarily made for redux reducers, just to make updating existing values more
 * readable and to avoid too many spreads / object assigns in the switch tree.
 *
 * This also does not follow lodash's merge behaviour wherein the merge
 * coalesces into the truthy value rather than the newer value if the new value
 * is undefined.
 * https://github.com/lodash/lodash/issues/5242
 * @param obj The full object
 * @param keyPath The key to the sub-object intended for update. Like NamePath in antd form, you can use an array of keys
 * to access nested entries. e.g. ['a', 'b', 'c'] will update obj.a.b.c
 * @param subObject The sub-object whose properties to be merged into the object. Pass `undefined` to remove the it
 * from the original object.
 * @returns The updated object in full (deep copied)
 */
export const upsertSafeChildProperties = (
  obj: object | undefined,
  keyPath: (string | number)[],
  subObject: object | undefined
) => {
  if (keyPath.length === 0) return obj;

  if (keyPath.length === 1) {
    const key = keyPath[0];
    const newObj = { ...obj };

    if (subObject === undefined) {
      delete newObj[key];
    } else {
      newObj[key] = { ...obj?.[key], ...subObject };
    }
    return newObj;
  }

  const [key, ...restKeys] = keyPath;
  return {
    ...obj,
    [key]: upsertSafeChildProperties(obj?.[key], restKeys, subObject)
  };
};
