import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const DEFAULT_NUMBER_OF_RETRIES = 3;
const DEFAULT_RETRY_DELAY = 2000;

export async function retryAsync<T>(
  toRetry: () => Promise<T>,
  retries: number,
  delay: number
): Promise<T> {
  let attempts = 0;

  while (attempts < retries) {
    try {
      return await toRetry();
    } catch (error) {
      attempts++;

      if (attempts >= retries) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error('Retry attempts exhausted');
}

type RequestFunction<T> = () => Promise<AxiosResponse<T>>;

// TODO: Disable retry when error is not a timeout https://farmbot.atlassian.net/browse/FMBT-801
export async function getRequest<T>(
  path: string,
  config?: AxiosRequestConfig,
  retryOnError: boolean = true // default to retry on error
): Promise<AxiosResponse<T>> {
  const request: RequestFunction<T> = () => axios.get<T>(path, { ...(config || {}) });
  if (retryOnError) {
    return retryAsync(request, DEFAULT_NUMBER_OF_RETRIES, DEFAULT_RETRY_DELAY);
  } else {
    return request();
  }
}

export async function postRequest<T, D>(path: string, data: D): Promise<AxiosResponse<T, D>> {
  return axios.post<T, AxiosResponse<T, D>, D>(path, data);
}

export async function putRequest<T, D>(path: string, data: D): Promise<AxiosResponse<T, D>> {
  return axios.put<T, AxiosResponse<T, D>, D>(path, data);
}

export async function patchRequest<T, D>(path: string, data: D): Promise<AxiosResponse<T, D>> {
  return axios.patch<T, AxiosResponse<T, D>, D>(path, data);
}

export async function deleteRequest<T>(path: string): Promise<AxiosResponse<T>> {
  return axios.delete<T>(path);
}