import keyBy from 'lodash/keyBy';

import { ApplicationActions } from 'store/types';
import { upsertSafeChildProperties } from 'utils/Object/properties/upsert-child-property';

import ActionTypes from './constants';
import { IPCamerasState } from './types';

export const initialState: IPCamerasState = {};

function ipCamerasReducer(
  state: IPCamerasState = initialState,
  action: ApplicationActions
): IPCamerasState {
  switch (action.type) {
    case ActionTypes.FETCH_CAMERAS_BY_SITE_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ActionTypes.FETCH_CAMERA_BY_ID_SUCCESS: {
      const { payload } = action;
      return upsertSafeChildProperties(state, [payload.id], payload);
    }
    case ActionTypes.SET_IP_CAMERA_PREVIEW: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          preview: payload.preview,
          errorRefreshingPreview: undefined
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_PREVIEW_ERROR: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          errorRefreshingPreview: payload.error
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_STATE: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          metadata: payload.metadata
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_CAPABILITIES: {
      const { payload } = action;
      return upsertSafeChildProperties(state, [payload.ipCameraId, 'capabilities'], payload.partialCapabilities);
    }
    case ActionTypes.SET_IP_CAMERA_PHOTOS: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          photoList: payload.photoList
          // TODO: delete this line once we have more images in BE to test for pagination
          // photoList: {
          //   ...payload.photoList,
          //   currentData: mockIPCameraPhotos,
          //   totalDataItems: mockIPCameraPhotos.length
          // }
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_UNSEEN_PHOTO: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          unseenNewPhoto: payload.newPhoto
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_EVENTS: {
      const { payload } = action;
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          eventList: payload.events
        }
      };
    }
    case ActionTypes.APPEND_IP_CAMERA_EVENTS: {
      const { payload } = action;
      const existingList = state[payload.ipCameraId]?.eventList || [];
      return {
        ...state,
        [payload.ipCameraId]: {
          ...state[payload.ipCameraId],
          eventList: [
            ...existingList,
            ...payload.events
          ]
        }
      };
    }
    case ActionTypes.REMOVE_FROM_IP_CAMERA_LIST: {
      const { payload: { id } } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case ActionTypes.SET_IP_CAMERA_PTZ_PRESETS: {
      const { payload: { ipCameraId, presets } } = action;
      return {
        ...state,
        [ipCameraId]: {
          ...state[ipCameraId],
          ptzPresets: keyBy(presets, 'id')
        }
      };
    }
    case ActionTypes.SET_IP_CAMERA_PTZ_PRESET: {
      const { payload: { ipCameraId, preset } } = action;

      return {
        ...state,
        [ipCameraId]: {
          ...state[ipCameraId],
          ptzPresets: {
            ...state[ipCameraId]?.ptzPresets,
            [preset.id]: preset
          }
        }
      };
    }
    case ActionTypes.REMOVE_IP_CAMERA_PTZ_PRESET: {
      const { payload: { ipCameraId, presetId } } = action;
      const presets = state[ipCameraId].ptzPresets;

      if (presets) {
        delete presets[presetId];
      }

      return {
        ...state,
        [ipCameraId]: {
          ...state[ipCameraId],
          ptzPresets: presets
        }
      };
    }
    default:
      return state;
  }
}

export default ipCamerasReducer;